.switch {
  width: 100px;
  height: 100px;
  position: relative;
}

.switch label {
  display: block;
  width: 25%;
  height: 25%;
  position: relative;
  border-radius: 50%;
  background: #eaeaea;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25), inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -5px 5px rgba(100, 100, 100, 0.1), inset 0 5px 5px rgba(255, 255, 255, 0.3);
}

.switch label:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: -8%;
  right: -8%;
  bottom: -8%;
  left: -8%;
  border-radius: inherit;
  background: #ddd; /* Fallback */
  background: linear-gradient(#ccc, #fff);
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.15), 0 2px 5px rgba(200, 200, 200, 0.1);
}

.switch label:before {
  content: '';
  position: absolute;
  width: 30%;
  height: 30%;
  left: 30%;
  top: 30%;
  border-radius: inherit;
  background: #969696; /* Fallback */
  background: radial-gradient(40% 35% at 0 0, #ccc, #969696 60%);
  box-shadow: inset 0 2px 4px 1px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 1), inset 0 1px 0 white;
}

.switch input:checked ~ label {
  /* Button */
  background: #e5e5e5; /* Fallback */
  background: linear-gradient(#dedede, #fdfdfd);
}

.switch input:checked ~ label:before {
  /* LED */
  background: #25d025; /* Fallback */
  background: radial-gradient(40% 35% at 0 0, #5aef5a, #25d025 60%);
  box-shadow: inset 0 3px 5px 1px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.4), 0 0 10px 2px rgba(0, 210, 0, 0.5);
}

.loadbar {
  display: inline-block;
  width: 25px;
  height: 200px;
  background-color: rgba(0, 100, 0);
  /* #f7c3157a */
  border: 1px solid #ccc;
  position: relative;
  border-radius: 5px;
}

.bar {
  font-size: 1vh;
  width: 100%;
  display: block;
  background-color: rgb(138, 5, 5);
  color: black;
  position: absolute;
  bottom: 0;
  border-radius: 5px;
}

.bar-empty {
  font-family: Poppins;
  transform: rotate(-90deg);
  font-size: 1vh;
  width: 200%;
  display: inline-block;
  color: black;
  position: absolute;
  bottom: -25%;
  border-radius: 5px;
  top: 50%;
  left: 240%;
}

.loadbar-alone {
  float: left;
  display: inline-block;
  width: 25px;
  height: 200px;
  background-color: #0c811f;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 5px;
}

.box {
  border: 1px solid #ccc;
  display: inline-block;
  width: 200px;
  background-color: #f7c3157a;
  height: 25px;
  border-radius: 5px;
  display: inline-block;
}

.infobar {
  background-color: rgba(30, 144, 255, 0.2);
  height: 20px;
  border-radius: 5px;
  float: left;
  margin: 3px;
  width: 50px;
  font-size: xx-small;
  font-weight: bolder;
  align-items: center;
}

.infobar-content {
  background-color: rgba(30, 144, 255, 0.2);
  width: 25px;
  border-radius: 5px;
  display: inline-block;
  float: left;
  position: relative;
  height: 20px;
  /* left: 4%; */
  margin: 3px;
}

.table-data {
  /*padding-left:0.70rem;*/
  font-size: x-small;
  background-color: rgba(30, 144, 255, 0.2);
  padding: 0.4rem;
}

.col-double-width {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
