.Modal {
  position: fixed;
  z-index: 1200;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /* padding: 16px; */
  left: 0%;
  top: 0%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
