.switch {
  width: 100px;
  height: 100px;
  position: relative;
}

.switch label {
  display: block;
  width: 25%;
  height: 25%;
  position: relative;
  border-radius: 50%;
  background: #eaeaea;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25), inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -5px 5px rgba(100, 100, 100, 0.1), inset 0 5px 5px rgba(255, 255, 255, 0.3);
}

.switch label:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: -8%;
  right: -8%;
  bottom: -8%;
  left: -8%;
  border-radius: inherit;
  background: #ddd; /* Fallback */
  background: linear-gradient(#ccc, #fff);
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.15), 0 2px 5px rgba(200, 200, 200, 0.1);
}

.switch label:before {
  content: '';
  position: absolute;
  width: 30%;
  height: 30%;
  left: 30%;
  top: 30%;
  border-radius: inherit;
  background: #969696; /* Fallback */
  background: radial-gradient(closest-side 40% 35%, #ccc, #969696 60%);
  box-shadow: inset 0 2px 4px 1px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 1), inset 0 1px 0 white;
}

.switch input:checked ~ label {
  /* Button */
  background: #e5e5e5; /* Fallback */
  background: linear-gradient(#dedede, #fdfdfd);
}

.switch input:checked ~ label:before {
  /* LED */
  background: #25d025; /* Fallback */
  background: radial-gradient(closest-side 40% 35%, #5aef5a, #25d025 60%);
  box-shadow: inset 0 3px 5px 1px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.4), 0 0 10px 2px rgba(0, 210, 0, 0.5);
}
