/* modal.css */

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1500;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
}

/* The actual modal “box” */
.modall {
  position: relative;
  z-index: 2200;
  opacity: 0;
  background: white;
  border-radius: 8px;
  will-change: transform, opacity;
}

@keyframes modalEnter {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalExit {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes overlayEnter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes overlayExit {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.modal-enter {
  animation: modalEnter 0.4s ease-out forwards;
}
.overlay-enter {
  animation: overlayEnter 0.4s ease-out forwards;
}

.modal-exit {
  animation: modalExit 0.4s ease-in forwards;
}
.overlay-exit {
  animation: overlayExit 0.4s ease-in forwards;
}
