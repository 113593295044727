.SuperplanOrder {
  font-size: 14px;
}
.SuperplanItems {
  line-height: 30px;
}
.SuperplanLeft {
  width: 40%;
  text-align: right;
}
.SuperplanRight {
  width: 60%;
  text-align: left;
  padding-left: 8px;
}
@media only screen and (max-width: 1200px) {
  .SuperplanOrder {
    font-size: 12px;
  }
  .SuperplanItems {
    line-height: 10px;
  }
  .SuperplanLeft {
    width: 35%;
    text-align: right;
  }
  .SuperplanRight {
    width: 60%;
    text-align: left;
    padding-left: 8px;
  }
}