// If you want to override variables do it here
@import 'variables';
button:focus {
  border: none;
  outline: none !important;
}
// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';

// If you want to add something do it here
@import '~@coreui/coreui/scss/_sidebar.scss';
.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  background: #f9b115;
  color: black;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown-toggle:hover {
  background: #f9b115;
  color: black;
  max-width: auto;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-dropdown:hover > .c-sidebar-nav-dropdown-toggle {
  background: #f9b115;
  color: black;
}

.c-sidebar .c-sidebar-nav-item:hover > .c-sidebar-nav-link {
  background: #f9b115;
  color: black;
}
.c-sidebar .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-toggle:hover {
  background: #f9b115;
  color: black;
}

.c-sidebar-minimized .c-sidebar-nav-item > .c-sidebar-nav-link:hover {
  max-width: 56px;
  overflow: hidden;
}

.c-sidebar-minimized .c-sidebar-nav-dropdown > .c-sidebar-nav-link:hover {
  max-width: 360px;
  overflow: hidden;
}
.c-sidebar-nav-dropdown-items {
  overflow: hidden;
}

.c-sidebar-minimized .c-sidebar-nav-dropdown-items > .c-sidebar-nav-item > .c-sidebar-nav-link:hover {
  max-width: 360px;
  overflow: hidden;
}

@media (max-width: 700.98px) {
  .c-sidebar {
    display: block;
  }
  .c-sidebar-minimized .c-sidebar-nav-item > .c-sidebar-nav-link:hover {
    max-width: 360px;
    overflow: hidden;
  }
}

@import '~@coreui/coreui/scss/_header.scss';

.c-header {
  background: #f7c315;
}

.c-header .c-header-nav .c-header-nav-link,
.c-header .c-header-nav .c-header-nav-btn {
  color: rgba(0, 0, 21, 1);
}

/* Login Form style fix */
@import '~@coreui/coreui/scss/_card.scss';

.card-group > .card {
  flex: unset;
}

@import '~@coreui/coreui/scss/_reboot.scss';

@media only screen and (max-width: 800px) {
  .MuiTabs-flexContainer {
    display: grid !important;
  }

  .MuiTab-wrapper {
    width: auto !important;
  }
}
